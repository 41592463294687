<template>
  <v-row 
    :style="{ opacity: old ? '20%' : null }"
  >
    <v-col
      v-for="(item, index) of items.filter(x => (!categoryFilter || x.CategoryID === categoryFilter) && (!hideRec || !x.Recurrent))"
      :key="`${item.ID}-${index}`"
      cols="12"
      sm="12"
      md="12"
      @click="() => onEdit(item)"
    >
      <event
        :contentTypeID="contentTypeID"
        :data="item"
        :locale="locale"
        :activityList="activityList"
        :eventType="eventType"
        :style="{ border: item.Recurrent ? '1px solid orange' : null,
                  opacity: item.HideEvent ? '20%' : '100%'
                }"
      />
    </v-col>
    <v-col
      v-if="!old && !disabledAdd"
      cols="12"
      sm="12"
      md="12"
      style="text-align: center;"
    >
      <v-btn
        @click="handleAdd"
        color="primary"
        dark
        outlined
        small
        style="margin-bottom: 20px;"
      >
        <v-icon>mdi-plus</v-icon> {{ $t('common.add', locale) }}
      </v-btn>
    </v-col>
  </v-row>
</template>
<script>
import api from '@/services/api'
import Event from './Event'
export default {
  components: {
    Event,
  },
  props: {
    planID: {
      type: String,
      required: true,
    },
    contentTypeID: {
      type: String,
      required: true,
    },
    config: {
      type: Array,
      default: null,
    },
    day: {
      type: Object,
      required: true,
    },
    disabledAdd: {
      type: Boolean,
      default: true,
    },
    onEdit: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
    forceRefresh: {
      type: String,
      default: null,
    },
    activityList: {
      type: Array,
      default: null,
    },
    eventType: {
      type: String,
      required: true,
    },
    categoryFilter: {
      type: String,
      default: null,
    },
    old: {
      type: Boolean,
      default: false,
    },
    hideRec: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    items: [],
  }),
  watch: {
    'contentTypeData.Categories' () {
      this.handleGetData()
    },
    'contentTypeDataID' () {
      this.handleGetData()
    },
    'day.date' () {
      this.handleGetData()
    },
    forceRefresh () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    handleGetData () {
      this.items = []
      api.getAllWithoutLimit ('dviewcontent', `v1/public/activity-barcelo-01/events/${this.contentTypeID}/${this.planID}/${this.day.date}`)
        .then((response) => {
          this.items = response.map(item => {
            item.Title = item.Title ? JSON.parse(item.Title) : { es: '' }
            item.Description = item.Description ? JSON.parse(item.Description) : { es: '' }
            item.Place = item.Place ? JSON.parse(item.Place) : { es: '' }
            return item
          })
        })
    },
    handleAdd () {
      this.onEdit({
        EventID: null,
        ContentTypeID: this.contentTypeID,
        PlanID: this.planID,
        Time: 960,
        TimeEnd: 1020,
        Date: this.day.date,
        WeekdayID: this.day.weekdayID,
      })
    },
  },
}
</script>



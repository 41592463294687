import { render, staticRenderFns } from "./Event.vue?vue&type=template&id=c4058556&scoped=true&"
import script from "./Event.vue?vue&type=script&lang=js&"
export * from "./Event.vue?vue&type=script&lang=js&"
import style0 from "./Event.vue?vue&type=style&index=0&id=c4058556&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c4058556",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAlert,VCard,VCardSubtitle,VCardTitle,VIcon,VImg,VTooltip})
